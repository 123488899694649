export default function Example({ data, title }: { data: any, title?: string }) {
    return (
        <div className="bg-bc-gray py-8 sm:py-12">
            <div className="mx-auto">
                <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-16 lg:grid-cols-4">
                    {/* Key Stats Section */}
                    <div className="sm:col-span-2 text-left pl-4 sm:pl-0">
                        {title && <h4 className="text-[20px] md:text-[28px] font-medium font-reckless text-maroon">{title}</h4>}
                    </div>
                    {/* Stats Section */}
                    {data?.map((stat: any) => (
                        <div
                            key={stat.id}
                            className="mx-auto flex px-4 sm:max-w-xs flex-col gap-y-4 w-full"
                        >
                            <dt className="text-base md:text-xl font-light text-gray-600">{stat.name}</dt>
                            <dd className="order-first text-4xl md:text-6xl font-light font-reckless text-gray-900 text-maroon">
                                {stat.value}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
