"use client";

import { useState } from 'react';
import { toast } from 'sonner';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { handler } from "@/components/data-section/footer-data";
import { useRouter, useSearchParams } from "next/navigation";
import { Pencil, ArrowRight, Mail, ChevronRight, Phone, Linkedin, Facebook, X } from "lucide-react";
import startsWithPrefix from '@/utils/pathUtils';
import { useLayoutContext } from '../../../useLayout';
import useRouterWithLanguage from "@/utils/useRouterWithLanguage";


const pageName = "Footer";

const Footer: React.FC = () => {
  const router = useRouterWithLanguage();
  // const footerData = handler();
  const searchParams = useSearchParams();
  const userId = searchParams.get("user_id");
  const isFromView = searchParams.get("preview");
  const showEdit = searchParams.get("showEdit");
  const isEdit = showEdit === 'true' ? true : false;

  const { lng } = useLayoutContext();
  const footerData = require(`@/data/${lng}/footer/footer-card.json`);

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleViewPage = () => {
    router.push(
      `/preview?page=${pageName}&preview=true&user_id=${userId}&showEdit=false`
    );
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
/******  b2fa830c-7afb-42bf-b869-0d8a0f56e388  *******/
  const handleRouter = (path: string) => {
    router.push(path);
  };

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isChecked) {
      toast.error("Please agree to the Privacy Policy before subscribing.");
      return;
    }

    try {
      const email = formData.email.trim();
      if (!email || email === '') {
        toast.error("Please enter your email address.");
        return;
      }
      const payload = {
        email: formData.email,
      }
      setIsLoading(true); // Show loader
      const response = await fetch('/api/subscribe-newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || 'Subscription successful.');
        // Optionally reset the form
        setFormData({
          email: '',
        });
      } else {
        const error = await response.json();
        toast.error(`Error: ${error.message}`);
        console.error(error);
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="bg-[#29292b] text-[#f7f7f7] overflow-hidden">
      {isEdit && (
        <div className="absolute right-4 top-4">
          <Button variant="outline" className="text-[#f7f7f7]" onClick={handleViewPage}>
            <Pencil />
          </Button>
        </div>
      )}
      <footer className="bg-[#29292b] text-[#f7f7f7]">
        {/* Top Section */}
        <div className="mx-auto px-4 grid grid-cols-1 lg:grid-cols-[55%,44%] xl:!grid-cols-[60%,39%] gap-8 border-[#434345]">
          {/* Left Section: Newsletter */}
          <div className="col-span-1 py-12 w-full lg:w-1/2">
            <h4 className="pb-4 opacity-80">
              {footerData?.title}
            </h4>
            <form onSubmit={handleSubscribe} className="mt-0 flex items-center border-b-[0.2px] border-[#434345]">
              <input
                type="email"
                placeholder={footerData?.emailPaceholder}
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="w-full py-2 bg-transparent text-[#f7f7f7] placeholder-gray-600 rounded-l-md focus:outline-none"
              />
              <button
                type="submit"
                className={`bg-transparent text-[#f7f7f7] py-2 px-2 rounded-r-md hover:bg-indigo-500 focus:outline-none ${!isChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!isChecked || isLoading}
              >
                →
              </button>
            </form>
            <div className="mt-4">
              <div className="flex items-start gap-2">
                <input
                  id="privacyPolicy"
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  className="h-4 w-4 peer hidden"
                />

                <label
                  htmlFor="privacyPolicy"
                  className="h-4 w-4 flex items-center justify-center rounded-none border border-gray-600 bg-transparent peer-checked:border-gray-500 cursor-pointer"
                >
                  {/* Optional checkmark */}
                  {isChecked ? (
                    <svg
                      className="w-4 h-4 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
                    </svg>
                  ) : <div className="w-4 h-4"></div>}
                </label>

                <p className="text-xs text-gray-400 opacity-60">
                  {footerData?.termsAndConditions.agree} <a onClick={() => handleRouter("/privacy-policy")} className="cursor-pointer hover:underline">{footerData?.termsAndConditions.privacyPolicy}</a> {footerData?.termsAndConditions.content}
                </p>
              </div>
            </div>
          </div>

          {/* Middle Section: Links */}
          <div className="col-span-1 lg:border-l border-[rgb(67,67,69)] md:px-8">
            <div className="py-12 grid grid-cols-1 lg:grid-cols-2 gap-4">
              {footerData?.footerData?.map((section: any, index: number) => (
                <div key={index}>
                  <label className="opacity-50">{section.title}</label>
                  <ul className="footer mt-2 space-y-2 text-sm">
                    {section.links.map((link: any, linkIndex: number) => {
                      if (link?.disabled) return;
                      return (
                        <li key={linkIndex}>
                          <a
                            onClick={() => handleRouter(link.href)}
                            className="hover:text-[#FF977E] font-light text-base text-[#fafafa] cursor-pointer"
                          >
                            {link.name}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>


        {/* Bottom Section with Divider */}
        <div className="mx-auto px-4 grid grid-cols-1 lg:grid-cols-[55%,44%] xl:!grid-cols-[60%,39%] gap-8 border-t border-[#434345]">
          {/* Left Section: Newsletter */}
          <div className="col-span-1 py-12 w-full lg:w-1/2 flex items-end justify-start">
            <div className="flex justify-start">
              <a onClick={() => handleRouter("/home")} className="-m-1.5 p-1.5 cursor-pointer">
                <img
                  className="w-[340px] md:w-[380px] h-[60px] md:h-[90px]"
                  src={`${startsWithPrefix(footerData?.logo_image[0])}`}
                  alt="Fuzionest Logo"
                />
              </a>
            </div>
          </div>

          {/* Middle Section: Links */}
          <div className="col-span-1 lg:border-l border-[#434345] md:px-8">
            <div className="pt-12 pb-[5rem] grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Social Links */}
              <div>
                <ul className="footer mt-2 space-y-2 text-sm">
                  {footerData?.socialLinks?.map((link: any, index: number) => (
                    <li key={index}>
                      <a
                        href={link.href}
                        className="hover:text-[#FF977E] font-light text-base cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Contact Info */}
              <div>
                <ul className="footer mt-2 space-y-2 text-sm">
                  {footerData?.contactInfo?.map((contact: any, index: number) => (
                    <li key={index}>
                      <a href={contact.href}
                        className="hover:text-[#FF977E] font-light text-base cursor-pointer"
                      >
                        {contact.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Footer Bottom */}
              <div className="py-4 text-center text-sm lg:col-span-2">
                <label>
                  {footerData?.footerBottom?.map((item: any, index: number) => {
                    if (item.type === "text") {
                      return (
                        <span key={index} className={item.className || ""}>
                          {item.content}
                        </span>
                      );
                    }
                    if (item.type === "link") {
                      return (
                        <span
                          key={index}
                          onClick={() => handleRouter(item.href)}
                          className={item.className}
                        >
                          {item.content}
                        </span>
                      );
                    }
                    return null;
                  })}
                </label>

              </div>
            </div>
          </div>
        </div>


      </footer>


    </section >
  );
};

export default Footer;
