export default function Example({ data }: { data: any }) {
    return (
        <div className="bg-bc-gray pb-12 pt-24 sm:pt-32">
            <div className="mx-auto px-4 sm:px-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
                    {/* Left Side - Empty Space */}
                    <div className="hidden lg:block"></div>

                    {/* Right Side - Content */}
                    <div>
                        <h4 className="text-maroon">{data?.heading}</h4>
                        <h2 className="mt-4 text-gray-900">
                            {data?.title}
                        </h2>
                        <p className="mt-6 text-gray-600">
                            {data?.description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
